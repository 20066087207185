import sortBy from 'lodash.sortby';

export function sortByDate(objects, dateAccessor, isAscending = false) {
  let sorted = sortBy(
    objects,
    (l) => {
      const [day, month, year] = l[dateAccessor].split('.');
      return new Date(parseInt(year), parseInt(month) + 1, parseInt(day));
    }
  );

  if (isAscending) {
    return sorted.reverse();
  }

  return sorted;
}
