export class Star {
  constructor(id, name, profileImage, dateOfBirth, dateOfDeath, afterword, linkToHavaneseGalery) {
    this.id = id;
    this.name = name;
    this.dateOfBirth = dateOfBirth;
    this.dateOfDeath = dateOfDeath;
    this.afterword = afterword;
    this.profileImage = profileImage;
    this.linkToHavaneseGalery = linkToHavaneseGalery;
  }
}
