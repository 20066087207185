import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './starsModal.css';
import {sortByDate} from "../utils/sortByDate";

class StarsModal extends React.Component {
  render() {
    const sortedStars = sortByDate(this.props.stars, 'dateOfDeath');
    return (
      <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>Sternchen</ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <div className="row">
              {
                sortedStars.map(star => {
                  return (
                    <div key={star.id} className="col-12 mb-3">
                      <img alt={star.profileImage.description} className="stars-modal--image mx-auto d-block mb-2 rounded-circle img-fluid" src={star.profileImage.url} />
                      <h3 className="text-center">{star.name}</h3>
                      <p className="text-center mb-1"><span className="mr-1">&#42;</span>{star.dateOfBirth}</p>
                      <p className="text-center"><span className="mr-1">&#10013;</span>{star.dateOfDeath}</p>
                      <p className="text-center">{star.afterword}</p>
                      <div className="text-center">
                        <a className="buttonDesign" href={star.linkToHavaneseGalery} target="_blank" rel="noopener noreferrer">{star.nickname} in der Havaneser Galery</a>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default StarsModal;
