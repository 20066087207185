import {Havanese} from "../models/Havanese";
import {Image} from "../models/Image";
import {DogShow} from "../models/DogShow";

export class OurDogsService {
  constructor(rawDogs) {
    this.ourDogs = this.parseRawDogsEdges(rawDogs.edges);
  }

  getOurDogs() {
    return this.ourDogs;
  }

  parseRawDogsEdges(rawDogsEdges) {
    return rawDogsEdges.map(edge => {
      const node = edge.node;
      const {id, name, nickname, breeder, shortDescription, havaneseGaleryUrl, birthDate, dateOfDeath, isOwned, dogShows} = node;
      const profileImage = new Image(`https:${node.profileImage.resize.src}`, node.profileImage.file.fileName, node.profileImage.description);
      const additionalImages = node.additionalImages.map(image => new Image(`https:${image.resize.src}`, image.file.fileName, image.description));
      const parsedDogShows = dogShows?.map(dogShow => new DogShow(dogShow)) || [];
      return new Havanese(id, name, nickname, breeder, shortDescription.shortDescription, havaneseGaleryUrl, birthDate, dateOfDeath, isOwned, profileImage, additionalImages, parsedDogShows);
    });
  }

}
