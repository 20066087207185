import React from "react";
import HavaneseModal from "./havaneseModal";

class HavaneseProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isModalOpen: false};
  }

  toggleModal() {
    this.setState({isModalOpen: !this.state.isModalOpen});
  }

  render() {
    return [
      <div key={`${this.props.havanese.id}-picture`}>
        <img className="rounded-circle img-fluid" src={this.props.havanese.profileImage.url} alt={this.props.havanese.profileImage.description}/>
        <h3>{this.props.havanese.nickName}</h3>
        <p><span className="mr-1">&#42;</span>{this.props.havanese.birthDate}</p>
        <p>{this.props.havanese.shortDescription}</p>
      </div>,
      <div key={`${this.props.havanese.id}-more`}>
        <p>
          <button className="buttonDesign" onClick={() => this.toggleModal()}>nähere Infos</button>
          <HavaneseModal havanese={this.props.havanese} onToggle={() => this.toggleModal()} isOpen={this.state.isModalOpen}/>
        </p>
        <p>
          <a className="buttonDesign" href={this.props.havanese.havaneseGaleryUrl} target="_blank" rel="noopener noreferrer">
            {this.props.havanese.nickName} in der Havaneser Galery
          </a>
        </p>
      </div>,
    ]
  }
}

export default HavaneseProfile;
