import React from 'react';
import { Carousel, CarouselIndicators, CarouselItem, CarouselCaption, CarouselControl } from 'reactstrap';

class ImageGalery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeIndex: 0};
  }

  goToIndex(index) {
    this.setState({activeIndex: index});
  }

  next() {
    const nextIndex = (this.state.activeIndex + 1) % (this.props.images.length);
    this.setState({activeIndex: nextIndex});
  }

  previous() {
    const imageCount = this.props.images.length;
    const previousIndex = (this.state.activeIndex - 1 + imageCount) % imageCount;
    this.setState({activeIndex: previousIndex});
  }

  render() {
    const slides = this.props.images.map((image) => {
      return (
        <CarouselItem key={image.fileName}>
          <img className="d-block w-100" src={image.url} alt={image.description} />
          <CarouselCaption captionText={image.description} />
        </CarouselItem>
      );
    });
    return (
      <Carousel interval={false} activeIndex={this.state.activeIndex} next={() => this.next()} previous={() => this.previous()}>
        {/* The Carousel Indicator needs a src property tho we give it to him */}
        <CarouselIndicators items={ this.props.images.map(image => ({src: image.url})) }
                            activeIndex={this.state.activeIndex}
                            onClickHandler={(index) => this.goToIndex(index)}
        />
        {slides}
        <CarouselControl direction="next" onClickHandler={() => this.next()} />
        <CarouselControl direction="prev" onClickHandler={() => this.previous()} />
      </Carousel>
    );
  }
}

export default ImageGalery;
