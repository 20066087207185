import React from 'react';
import LitterBoxTile from "./litterBoxTile";
import LitterBoxModal from "./litterBoxModal";
import './litterBoxSection.css';
import {sortByDate} from "../utils/sortByDate";

class LitterBoxSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedLitterBoxModal: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(litterBoxId) {
    this.setState(prevState => ({
      openedLitterBoxModal: prevState.openedLitterBoxModal === litterBoxId ? null : litterBoxId,
    }));
  }

  render() {
    const sortedLitterBoxes = sortByDate(this.props.litterBoxes, 'thrownAt', true);
    return (
      <section id="litterBox" className="litter-box-section py-5">
        <div className="container">
          <h2>Unsere Wurfkiste</h2>
          <div className="row no-gutter">
            {sortedLitterBoxes.map(litterBox => {
              return (
                <div key={litterBox.id} className="col-xs-6 col-md-4 col-lg-3">
                  <LitterBoxTile
                    litterBox={litterBox}
                    onClick={() => this.toggleModal(litterBox.id)}
                  />
                  <LitterBoxModal
                    isOpen={litterBox.id === this.state.openedLitterBoxModal}
                    litterBox={litterBox}
                    onToggle={() => this.toggleModal(litterBox.id)}
                  />
                </div>
              )
            })}
          </div>
        </div>

      </section>
    );
  }
}

export default LitterBoxSection;
