import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DogShowTable from "./dogShowTable";
import ImageGalery from "./imageGalery";

class HavaneseModal extends React.Component {
  render() {
    return (
      <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          {this.props.havanese.nickName}
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            {
              this.props.havanese.dogShows.length > 0 && (
                <>
                  <h5 className="text-center">Ausstellungen</h5>
                  <div className="row justify-content-center">
                    <div className="col-xs-12">
                      <DogShowTable dogShows={this.props.havanese.dogShows} />
                    </div>
                  </div>
                </>
              )
            }
            <h5 className="text-center">{this.props.havanese.nickName} in Aktion</h5>
            <div>
              <ImageGalery images={this.props.havanese.additionalImages} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default HavaneseModal;
