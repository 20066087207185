import React from 'react';
import { graphql } from "gatsby";
import Layout from '../components/layout';
import Navigation from "../components/navigation";
import { BigPictureService } from "../services/BigPictureService";
import WelcomeSection from '../components/welcomeSection';
import NewsSection from "../components/newsSection";
import {NewsService} from "../services/NewsService";
import AboutUsSection from "../components/aboutUsSection";
import {AboutUsService} from "../services/AboutUsService";
import OurDogsSection from "../components/ourDogsSection";
import {OurDogsService} from "../services/OurDogsService";
import LitterBoxSection from "../components/litterBoxSection";
import {LitterBoxService} from "../services/LitterBoxService";
import Footer from "../components/footer";
import {StarsService} from "../services/StarsService";

const IndexPage = ({data}) => {
  const bigPictureService = new BigPictureService(data.bigPictures);
  const newsService = new NewsService(data.news);
  const aboutUsService = new AboutUsService(data.aboutUs);
  const ourDogsService = new OurDogsService(data.ourDogs);
  const litterBoxService = new LitterBoxService(data.litterBoxes);
  const starsService = new StarsService(data.stars);

  const bigPictures = bigPictureService.getBigPictures();
  const news = newsService.getNews();
  const aboutUs = aboutUsService.getAboutUs();
  const ourDogs = ourDogsService.getOurDogs();
  const litterBoxes = litterBoxService.getLitterBoxes();
  const stars = starsService.getStars();

  const scrollToSection = (identifier) => {
    document
      .querySelector(`#${identifier}`)
      .scrollIntoView({behavior: 'smooth', block: 'start'});
  };

  return (
    <Layout>
      <Navigation onClick={scrollToSection} />
      <WelcomeSection bigPictures={bigPictures} />
      {news.length > 0 && <NewsSection news={news} /> }
      <AboutUsSection aboutUs={aboutUs} />
      <OurDogsSection ourDogs={ourDogs} />
      <LitterBoxSection litterBoxes={litterBoxes} />
      <Footer stars={stars} />
    </Layout>
)
};

export default IndexPage

export const query = graphql`
  query havaneseData {
    bigPictures: allContentfulAsset(filter: {title: {regex: "/bigPicture*/"}}) {
      edges {
        node {
          title,
          file {
            fileName
            url
          }
        }
      }
    },
    news: allContentfulNeuigkeit {
      edges {
        node {
          id,
          date(formatString: "DD.MM.YYYY")
          heading,
          newstext {
            newstext
          },
          image {
            description,
            file {
              fileName
            }
            resize(width:600 height:400) {
              src
            }
          }
        }
      }
    },
    aboutUs: allContentfulUberUns {
      edges {
        node {
          text {
            id,
            text
          },
          image {
            id,
            description
            file {
              url
            }
          }
        }
      }
    }
    ourDogs: allContentfulHavaneser(filter: {
      isOwned: {eq: true}
      dateOfDeath: {eq: null}
    }) {
      edges {
        node {
          id
          name
          nickname
          breeder
          shortDescription {
            shortDescription
          }
          havaneseGaleryUrl
          birthDate(formatString: "DD.MM.YYYY")
          isOwned
          profileImage {
            description
            file {
              fileName
            }
            resize(width:400 height:400) {
              src
            }
          }
          additionalImages {
            description
            file {
              fileName
            }
            resize(width:600 height: 400) {
              src
            }
          }
          dogShows {
            id
            location
            date(formatString: "DD.MM.YYYY")
            organization
            category
            rating
            title
          }
        }
      }
    }
    litterBoxes: allContentfulWurf {
      edges {
        node {
          id,
          nameOfLitterBox,
          thrownAt(formatString: "DD.MM.YYYY")
          picture {
            id
            file {
              fileName
            }
            resize(width: 400, height: 400) {
              src
            }
          }
          father {
            id,
            name,
            nickname,
            profileImage {
              description,
              file {
                fileName,
              }
              resize(width: 200, height:200) {
                src,
              }
            }
          }
          mother {
            id,
            name,
            nickname,
            profileImage {
              description,
              file {
                fileName,
              }
              resize(width: 200, height:200) {
                src,
              }
            }
          }
          linkToHavaneseGalery,
          description {
            description
          }
          puppies {
            id,
            puppyName,
            furColor,
            sex,
            saleStatus,
            photos {
              description,
              file {
                fileName,
              },
              resize(width: 600, height: 400) {
                src,
              }
            }
          }
          additionalImages {
            description
            file {
              fileName,
            }
            resize(width: 600, height: 400) {
              src
            }
          }
        }
      }
    },
    stars: allContentfulHavaneser(filter: {
      isOwned: { eq: true }
      dateOfDeath: {ne: null}
    }) {
      edges {
        node {
          id
          nickname
          birthDate(formatString: "DD.MM.YYYY")
          dateOfDeath(formatString: "DD.MM.YYYY")
          havaneseGaleryUrl
          afterword {
            afterword
          }
          profileImage {
            description
            file {
              fileName
            }
            resize(width:400 height:400) {
              src
            }
          }
        }
      }
    }
  }
`;
