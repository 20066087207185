import React from 'react';
import './litterBoxTile.css';

class LitterBoxTile extends React.Component {
  constructor(props) {
    super(props);
    this.litterBox = this.props.litterBox;
    this.onClick = this.props.onClick;
  }
  render() {
    return (
      <div className="image-wrapper overlay-slide-in-left">
        <img
          className="img-fluid"
          alt={this.litterBox.image.description}
          src={this.litterBox.image.url}
        />
        <div className="image-overlay-content">
          <h3>Der {this.litterBox.name}</h3>
          <div>
            <p>Vater: {this.litterBox.father && this.litterBox.father.name}</p>
            <p>Mutter: {this.litterBox.mother && this.litterBox.mother.name}</p>
            <p>geb. am: {this.litterBox.thrownAt}</p>
            <p>{ this.getPuppySexCountText() }</p>
          </div>
          <button
            type="button"
            className="buttonDesign"
            onClick={this.onClick}
          >
            Blick hinein!
          </button>
        </div>
      </div>
    );
  }

  getPuppySexCountText() {
    const puppySexCount = this.getPuppySexCount();

    let femaleText = '';
    let maleText = '';

    if (puppySexCount[0] === 1) maleText = '1 Rüde';
    if (puppySexCount[0] > 1) maleText = `${puppySexCount[0]} Rüden`;

    if (puppySexCount[1] === 1) femaleText = '1 Hündin';
    if (puppySexCount[1] > 1) femaleText = `${puppySexCount[1]} Hündinnen`;

    if (maleText && femaleText) return `${maleText} | ${femaleText}`;
    if (maleText && !femaleText) return maleText;
    if (!maleText && femaleText) return femaleText;
  }

  getPuppySexCount() {
    const puppySexCount = [0, 0];
    this.litterBox.puppies.forEach(puppy => {
      switch (puppy.sex) {
        case 'männlich':
          puppySexCount[0] += 1;
          break;
        case 'weiblich':
          puppySexCount[1] += 1;
          break;
        default:
          throw Error('no such sex');
      }
    });
    return puppySexCount;
  }
}

export default LitterBoxTile;
