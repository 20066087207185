import React from 'react';
import './newsSection.css';
import NewsCard from "./newsCard";
import {sortByDate} from "../utils/sortByDate";

export default class NewsSection extends React.Component {
  render() {
    const sortedNews =  sortByDate(this.props.news, 'date', false);
    return (
      <section id="news" className="py-5">
        <div className="container">
          <h2>Neuigkeiten</h2>
          <div className="row mb-3">
              {
                sortedNews.map((singleNews) => {
                  return [
                    <div key={singleNews.id} className="col-12 col-md-6 col-lg-4 d-flex mb-3">
                      <NewsCard newsModel={singleNews} />
                    </div>,
                  ]
                })
              }
          </div>
        </div>
      </section>
    )
  }
}
