import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './litterBoxModal.css';
import ImageGalery from "./imageGalery";

class LitterBoxModal extends React.Component {
  constructor(props) {
    super(props);
    this.litterBox = this.props.litterBox;
  }

  render() {
    return (
      <Modal size='lg' isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>{ this.litterBox.name }</ModalHeader>
        <ModalBody>
          <div>
            <div className="container-fluid mb-3">
              <h3 className="text-center mb-4">Die Eltern</h3>
              <div className="row mb-5">
                {
                  this.litterBox.mother && (<div className="col-6">
                    <img className="rounded-circle img-fluid mx-auto d-block mb-2" alt={this.litterBox.mother.profileImage.description} src={this.litterBox.mother.profileImage.url} />
                    <h4 className="text-center">Mutter</h4>
                    <p className="text-center">{ this.litterBox.mother.name }</p>
                  </div>)
                }
                {
                  this.litterBox.father && (<div className="col-6">
                    <img className="rounded-circle img-fluid mx-auto d-block mb-2" alt={this.litterBox.father.profileImage.description} src={this.litterBox.father.profileImage.url} />
                    <h4 className="text-center">Vater</h4>
                    <p className="text-center">{ this.litterBox.father.name }</p>
                  </div>)
                }
              </div>
              <p className="text-center mb-3">{this.litterBox.description}</p>
              <div className="text-center mb-5">
                <a href={this.litterBox.linkToHavaneseGalery} target="_blank" rel="noopener noreferrer" className="buttonDesign">Abstammungstafel</a>
              </div>
            </div>
            <div className="container-fluid">
              <h3 className="text-center mb-4">Die Welpen</h3>
              <div className="row">
                {
                  this.litterBox.puppies.map((puppy) => {
                    return (
                      <div key={puppy.id} className="col-12 col-sm-6 mb-5">
                        <h4 className={`text-center ${puppy.salesStatus && puppy.salesStatus !== 'ohne Status' ? 'mb-0' : 'mb-4' }`}>{ puppy.name }</h4>
                        { puppy.salesStatus && puppy.salesStatus !== 'ohne Status' && <span className="text-center d-block">({ puppy.salesStatus })</span> }
                        <ImageGalery images={puppy.images} />
                      </div>
                    )
                  })
                }
              </div>
              {
                this.litterBox.additionalImages.length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <h4 className="text-center">weitere Fotos</h4>
                      { <ImageGalery images={this.litterBox.additionalImages} /> }
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default LitterBoxModal;
