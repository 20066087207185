import {Image} from "../models/Image";
import {Star} from "../models/Star";

export class StarsService {
  constructor(rawStars) {
    this.stars = this.parseRawStarsEdges(rawStars.edges);
  }

  getStars() {
    return this.stars;
  }

  parseRawStarsEdges(rawStarsEdges) {
    return rawStarsEdges.map(edge => {
      const node = edge.node;
      const {id, nickname, birthDate, dateOfDeath, afterword, profileImage, havaneseGaleryUrl} = node;
      const image = new Image(`https:${node.profileImage.resize.src}`, profileImage.file.fileName, profileImage.description);
      return new Star(id, nickname, image, birthDate, dateOfDeath, afterword && afterword.afterword, havaneseGaleryUrl);
    });
  }
}
