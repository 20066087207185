import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class HavaneseInfoModal extends React.Component {
  render() {
    return (
      <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>Der Havaneser</ModalHeader>
        <ModalBody>
          <p>
            <strong>Herkunft</strong>
            <br/>
            Der Havaneser gehört zur Rassegruppe der Bichons, wie auch der Malteser, der Bologneser, der Bichon frisé, das Löwchen und der Coton de Tulear. Zu seinen Verwandten zählen also uralte Hunderassen die vor sehr vielen Jahren alle untereinander hervorgegangen sind. Schon alleine die große Ähnlichkeit der Bichonrassen untereinander lässt auf eine Verwandtschaft schließen. Der Name „Bichon“ bedeutet im französischen „verhätscheln“. Bei den adligen Damen der feinen Gesellschaft, waren solche Hündchen äußerst beliebt.
          </p>
          <p>
            <strong>Rassegeschichte</strong>
            <br/>
            Es gibt viele Theorien, wie der Havaneser entstanden sein könnte. Eine ist, dass der Havaneser durch Handelskapitäne von Spanien nach Kuba gebracht wurde. Diese cleveren Schiffskapitäne benutzten die kleinen Hündchen als „Türöffner“ für ihre Geschäftsbeziehungen. Die kleinen Luxusgeschöpfe dienten also in erster Linie als Mitbringsel für die Damen der feinen Gesellschaft. Eine andere Theorie ist folgende: Auf Kuba existierte bis dahin eine Kleinhunderasse namens „Blanquito de la Habana“. Dies waren kleine weiße Hündchen mit lebhaftem, intelligentem Charakter. Es wird vermutet, dass durch Einkreuzung mit dem Pudel schließlich der Havaneser entstand. Die ersten Havaneser wurden damals auch als „Havana-Silk Dog“(Havana´s Seidenhündchen) bezeichnet, nach Kubas Hauptstadt. Auf der Karibik-Insel, machten die kleinen Havaneser Karriere und wurden zum Symbol für Luxus. Solange, bis man sie durch den Sieg Castros 1959 aus Kuba verbannte - sie waren „lebendiges Symbol des Kapitalismus“. Einigen mutigen Exilkubanern, die während der Kennedy-Ära nach Amerika flüchteten, gelang es jedoch ihre Hunde mitzunehmen. Diesen Menschen haben wir es heute zu verdanken, dass die Rasse gerettet werden konnte. 1981 brachte eine deutsche Züchterin die ersten beiden Havaneser von Amerika nach Deutschland. Diese beiden Hunde bildeten den Grundstock für die heutige Havaneserzucht in Europa.
          </p>
          <p>
            <strong>Verwendung</strong>
            <br/>
            Der Havaneser ist ein fröhlicher, aufgeschlossener und freundlicher Familien- und Begleithund. Er ist sehr gelehrig und hat deshalb keine Probleme eine Begleithundeprüfung zu bestehen. Aber nicht nur das. Er ist sogar ein As auf dem Agility-Parcour. Für wanderlustige Menschen ist der Havaneser ein treuer Begleiter, der ausgedehnte Spaziergänge liebt. In früheren Zeiten wurden Havaneser in Wanderbühnen und im Zirkus angetroffen. Keinem anderen Hund konnte man so leicht Kunststückchen beibringen. Der Havaneser – der Clown in der Zirkusmanege.
          </p>
          <p>
            <strong>Charakter und Eigenschaften</strong>
            <br/>
            Der Havaneser ist außergewöhnlich intelligent und durch seine stets wache Neugierde sehr leicht zu erziehen. Mit seinem offenen Wesen und seinem unwiderstehlichen Charme erobert er im Sturm jedes Herz und wird sehr bald der Mittelpunkt jeder Familie. Selbst Liebhaber größerer Hunderassen verfallen dem natürlichen Charme des kleinen Havanesers. Trotz seiner geringen Größe will der Havaneser ernst genommen werden. Er will ein richtiges Familienmitglied sein und behandelt werden wie ein „Großer“. Havaneser sind sehr aufgeweckt, liebevoll und fröhlich. Ihre spielerische, kameradschaftliche und drollige Art macht ihn zum absoluten Liebling aller größeren Kinder. Er tobt sozusagen mit Kindern um die Wette und möchte unentwegt mit ihnen spielen. Für Kleinkinder ist ein Havaneserwelpe allerdings nicht der richtige Spielgefährte. Der Havaneser passt sich im Verhalten und im Bewegungsbedürfnis seinem Menschen an. Er kann sich auf besondere Weise still zurückziehen und auf der anderen Seite tobt er gern, ist sportlich und begeistert bei Spaziergängen und Wanderungen dabei. Der Havaneser ist sehr auf „seine“ Familie bezogen und ist glücklich, wenn er als vollwertiges Mitglied in der Familie aufgenommen wird. Manche Züchter beschreiben den Charakter des Havanesers ganz kurz:
            <cite>„Der Havaneser ist der Hund mit dem Herz aus Gold!"</cite>
          </p>
          <p>
            <strong>Besonderheiten</strong>
            <br/>
            Besonderes Augenmerk muss auf die Pflege des Haarkleides gerichtet werden. Sein seidiges Haarkleid verleiht dem Havaneser eine große Eleganz und außergewöhnliche Vornehmheit. Der Havaneser hat keinen saisonbedingten Haarwechsel. Lose Haare werden bei der Pflege des Haarkleides durch Bürsten und Kämmen entfernt. Der Havaneser ist kein Kläffer, kann sich jedoch in einen wütenden Bewacher des Hauses verwandeln, wenn er meint, seine Familie beschützen zu müssen. Immer wieder hervorzuheben ist seine besondere Anpassungsfähigkeit. Einerseits ungestümer, fröhlicher Wirbelwind und andererseits ruhig, ausgeglichen und kaum spürbar. Nicht umsonst wurde der Havaneser 1997 von einer Hundezeitschrift zum „Hund des Jahres“ gekürt. Die Lebenserwartung liegt realistisch bei etwa 12 bis 15 Jahren.
          </p>
          <p>
            <strong>Pflegehinweise</strong>
            <br/>
            Im Welpenalter ist es zu empfehlen, den Havaneser täglich zu kämmen bzw. zu bürsten. So gewöhnt er sich gut daran und empfindet die Pflegeprozedur später als Streicheleinheit. Beim Kämmen ist es ratsam, immer Stück für Stück vorzugehen. Es ist dabei unbedingt notwendig, immer bis auf die Haut vorzudringen. Wenn man nur auf der Oberfläche kämmt, droht das untere Haar zu verfilzen. Nachdem der junge Havaneser sich an die tägliche Pflege gewöhnt hat, reicht ein gründliches Bürsten und Kämmen alle zwei Tage bis zu einem Alter von ca. 2 Jahren. Das Haarkleid befindet sich ca. bis zum 2. Lebensjahr in der Entwicklung und neigt oft zu Verfilzungen. Später genügt ein Bürsten und Kämmen einmal pro Woche. Bei Havanesern, die zu Tränenfluss neigen, ist auf die Pflege der Augen zu achten. Um Verfärbungen zu vermeiden, die durch Tränenausscheidung entstehen, werden die Augenwinkel und das Haar unter den Augen mit einem weichen, feuchten Tuch gereinigt. Mit einem trockenen Tuch nachreiben, sodass das Haar wieder trocken ist. Die Pflegeprozedur wird mit etwas Übung später nur wenig Zeit in Anspruch nehmen. Wenn Sie Ihren Hund regelmäßig bürsten und kämmen, dürfte die tägliche Pflege nur ca. 10 min dauern.
          </p>
          <p>
            <strong>Gesundheit</strong>
            <br/>
            Der Havaneser ist ein sehr robuster und lebenslustiger Kleinhund. Gesundheitsprobleme wie HD kennt man nicht. Eine Veranlagung zu Kniescheibenluxation ist bei Kleinhunden vorhanden. Die Hunde unseres „Verbandes Deutscher Kleinhundezüchter„ werden vor ihrer Zuchtverwendung untersucht, um vorauszusetzen, dass nur mit gesunden Hunden gezüchtet wird. Im Ausland traten sehr vereinzelt Fälle von Katarakt auf. Um festzustellen, ob auch wir in Deutschland eventuell Probleme damit haben, wurde von der Zuchtleitung unseres Verbandes 1999 eine Augentersuchung für alle Havaneser-Zuchthunde gefordert. Diese Bestandsuntersuchung hatte zum Ergebnis, dass unsere deutschen Havaneser keinerlei Probleme mit Augenkrankheiten haben.
          </p>
          <p>
            <strong>Buchtipp</strong>
            <br/> Zoila Portuondo Guerra
            <br/> Der Havaneser
            <br/> Bede Verlag
            <br/>
          </p>
          <p>
            <strong>Quelle: </strong>
            <a href="http://www.welpen.de/hunderassen/havaneser" target="_blank" rel="noopener noreferrer">welpen.de</a>
          </p>
        </ModalBody>
      </Modal>
    );
  }
}

export default HavaneseInfoModal;
