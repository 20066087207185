import React from 'react';
import { Table } from 'reactstrap';

class DogShowTable extends React.Component {
  render() {
    return (
      <Table responsive>
        <thead className="thead-light">
          <tr>
            <th>Klasse</th>
            <th>Datum</th>
            <th>Austellung in</th>
            <th>Verein</th>
            <th>Bewertung</th>
            <th>Titel</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.dogShows.map(dogShow => {
              return (
                <tr key={dogShow.id}>
                  <td>{dogShow.category}</td>
                  <td>{dogShow.date}</td>
                  <td>{dogShow.location}</td>
                  <td>{dogShow.organization}</td>
                  <td>{dogShow.rating}</td>
                  <td>{dogShow.title}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    )
  };
}

export default DogShowTable;
