export class LitterBox {
  constructor(id, name, description, thrownAt, image, father, mother, puppies, linkToHavaneseGalery, additionalImages) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.image = image;
    this.father = father;
    this.mother = mother;
    this.puppies = puppies;
    this.linkToHavaneseGalery = linkToHavaneseGalery;
    this.thrownAt = thrownAt;
    this.additionalImages = additionalImages;
  }
}