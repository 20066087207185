import {BigPicture} from "../models/BigPicture";

export class BigPictureService {
  constructor(rawBigPictures) {
    this.bigPictures = this.parseRawBigPictureEdges(rawBigPictures.edges);
  }

  getBigPictures() {
    return this.bigPictures;
  }

  parseRawBigPictureEdges(rawBigPictureEdges) {
    return rawBigPictureEdges.map((edge) => {
      const node = edge.node;
      const title = node.title;
      const url = `https:${node.file.url}`;
      return new BigPicture(title, url);
    });
  }
}
