import {AboutUs} from "../models/AboutUs";

export class AboutUsService {
  constructor(rawAboutUs) {
    this.aboutUs = this.parseRawAboutUsEdges(rawAboutUs.edges);
  }

  getAboutUs() {
    return this.aboutUs;
  }

  parseRawAboutUsEdges(rawAboutUsEdges) {
    // about us should exist only once therefore only the first edge is used
    const node = rawAboutUsEdges[0].node;
    const text = node.text.text;
    const image = {url: `https:${node.image.file.url}`, description: node.image.description};
    return new AboutUs(text, image);
  }
}