import React from 'react';
import { Collapse } from 'reactstrap';
import splitSentences from '../utils/splitSentences';
import './aboutUsSection.css';

export default class AboutUsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
      ...this.computeCollapsedText(),
    };
  }

  computeCollapsedText() {
    const sentences = splitSentences(this.props.aboutUs.text);
    const text = sentences.slice(0, 8).join(' ').trim();
    const collapsedText = sentences.slice(8).join(' ').trim();
    return {text, collapsedText};
  };

  toggleCollapse() {
    this.setState({isCollapsed: !this.state.isCollapsed})
  }

  render() {
    let buttonContent;
    if (this.state.isCollapsed) {
      buttonContent = <span><span className="glyphicon glyphicon-menu-down" /> mehr sehen</span>;
    } else {
      buttonContent = <span><span className="glyphicon glyphicon-menu-up" /> weniger sehen</span>;
    }
    return (
      <section id="aboutus" className="aboutus py-5">

        <div className="container">
          <h2>Das sind Wir</h2>
          <div className="row">
            <div className="col-xs-12 col-md-5">
              <img src={this.props.aboutUs.image.url} className="img-fluid rounded" alt={this.props.aboutUs.image.description} />
            </div>
            <div className="col-xs-12 col-md-7">
              <p>{this.state.text}</p>
              <Collapse isOpen={!this.state.isCollapsed}>
                <p>{this.state.collapsedText}</p>
              </Collapse>
              <p>
                <button onClick={() => this.toggleCollapse()} className="buttonDesign">
                  {buttonContent}
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
