export class Havanese {
  constructor(
    id,
    name,
    nickName,
    breeder,
    shortDescription,
    havaneseGaleryUrl,
    birthDate,
    dateOfDeath,
    isOwned,
    profileImage,
    additionalImages,
    dogShows
  ) {
    this.id = id;
    this.name = name;
    this.nickName = nickName;
    this.breeder = breeder;
    this.shortDescription = shortDescription;
    this.havaneseGaleryUrl = havaneseGaleryUrl;
    this.birthDate = birthDate;
    this.dateOfDeath = dateOfDeath;
    this.isOwned = isOwned;
    this.profileImage = profileImage;
    this.additionalImages = additionalImages;
    this.dogShows = dogShows;
  }
}
