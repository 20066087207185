export class DogShow {
  constructor({id, location, organization, date, category, rating, title}) {
    this.id = id;
    this.location = location;
    this.organization = organization;
    this.date = date;
    this.category = category;
    this.rating = rating;
    this.title = title;
  }
}