import {LitterBox} from "../models/LitterBox";
import {HavaneseSimplified} from "../models/HavaneseSimplified";
import {Image} from "../models/Image";
import {Puppy} from "../models/Puppy";

export class LitterBoxService {
  constructor(rawLitterBoxes) {
    this.litterBoxes = this.parseRawLitterBoxEdges(rawLitterBoxes.edges);
  }

  getLitterBoxes() {
    return this.litterBoxes;
  }

  parseRawLitterBoxEdges(rawLitterBoxEdges) {
    return rawLitterBoxEdges.map(edge => {
      const node = edge.node;
      const {id, nameOfLitterBox, description, linkToHavaneseGalery, father, mother, puppies, picture, thrownAt, additionalImages} = node;

      const fatherProfileImage = father && new Image(`https:${father.profileImage.resize.src}`, father.profileImage.file.fileName, father.profileImage.description);
      const fatherObject = father && new HavaneseSimplified(father.id, father.name, father.nickname, fatherProfileImage);

      const motherProfileImage = mother && new Image(`https:${mother.profileImage.resize.src}`, mother.profileImage.file.fileName, mother.profileImage.description);
      const motherObject = mother && new HavaneseSimplified(mother.id, mother.name, mother.nickname, motherProfileImage);

      const litterBoxImage = new Image(`https:${picture.resize.src}`, picture.file.fileName, picture.description);

      let mappedAdditionalImages = [];
      if (additionalImages) {
        mappedAdditionalImages = additionalImages.map((img) => {
          return new Image(`https:${img.resize.src}`, img.file.fileName, img.description);
        });
      }

      const puppiesArray = puppies.map((puppy) => {
        const pictures = puppy.photos.map((photo) => {
          return new Image(`https:${photo.resize.src}`, photo.file.fileName, photo.description);
        });
        return new Puppy(puppy.id, puppy.puppyName, puppy.furColor, pictures, puppy.sex, puppy.saleStatus);
      });

      return new LitterBox(id, nameOfLitterBox, description.description, thrownAt, litterBoxImage, fatherObject, motherObject, puppiesArray, linkToHavaneseGalery, mappedAdditionalImages);
    });
  }
}
