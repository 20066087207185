import {News} from "../models/News";
import { Image } from "../models/Image";

export class NewsService {
  constructor(rawNews) {
    this.news = this.parseRawNewsEdges(rawNews.edges);
  }

  getNews() {
    return this.news;
  }

  parseRawNewsEdges(rawNewsEdges) {
    const results = [];
    rawNewsEdges.forEach((edge) => {
      const node = edge.node;
      const heading = node.heading;
      // Testnews
      if (heading.substr(0, 3) === '###') {
        return;
      }
      const id = node.id;
      const date = node.date;
      const newstext = node.newstext.newstext;
      const image = new Image(`https:${node.image.resize.src}`, node.image.file.fileName, node.image.description);
      results.push(new News(id, heading, newstext, image, date));
    });
    return results;
  }
}
