import React from 'react';

const NewsCard = ({newsModel}) => (
  <div className="card">
    <img className="card-img-top" src={newsModel.image.url} alt={newsModel.image.description} />
    <div className="card-body">
      <div className="card-title text-center">
        <h5 className="h5">{newsModel.heading}</h5>
        <span>{newsModel.date}</span>
      </div>
      <p className="card-text">{newsModel.newstext}</p>
    </div>
  </div>
);

export default NewsCard;
