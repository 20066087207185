import React from 'react';
import {Navbar, Nav, NavItem, NavLink, Collapse} from 'reactstrap';

class Navigation extends React.Component {
  render() {
    return(
      <Navbar expand="md" fixed='top' className='navbar-custom'>
        <Collapse navbar>
          <Nav navbar>
            <NavItem>
              <NavLink onClick={() => this.props.onClick('aboutus')} href='#'>Das sind Wir</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => this.props.onClick('dogs')} href='#'>Unsere Hunde</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => this.props.onClick('litterBox')} href='#'>Wurfkiste</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => this.props.onClick('contact')} href='#'>Kontakt</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  };
}

export default Navigation;
