import React from 'react';
import InfoPfeil from '../assets/img/Infopfeil.svg';
import KontaktPfeil from '../assets/img/Kontaktpfeil.svg';

import LegalNotesModal from './legalNotesModal';
import './footer.css';
import HavaneseInfoModal from "./havaneseInfoModal";
import StarsModal from "./starsModal";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLegalNotesModalOpen: false,
      isStarsModalOpen: false,
      isHavaneseInfoModalOpen: false,
    }
  }

  toggleLegalNotesModal() {
    this.setState(prevState => ({
      isLegalNotesModalOpen: !prevState.isLegalNotesModalOpen,
    }));
  }

  toggleHavaneseInfoModal() {
    this.setState(prevState => ({
      isHavaneseInfoModalOpen: !prevState.isHavaneseInfoModalOpen,
    }))
  }

  toggleStarsModal() {
    this.setState(prevState => ({
      isStarsModalOpen: !prevState.isStarsModalOpen,
    }))
  }

  render() {
    return (
      <footer id="contact" className="footer">
        <div className="container">
          { /* Footer for bigger than mobile */ }
          <div className="d-none d-lg-flex justify-content-between">
            <img alt="Kontakt" className="KontaktPfeil" src={KontaktPfeil} />
            <img alt="weitere Infos" className="InfoPfeil" src={InfoPfeil} />
          </div>
          <div className="row d-none d-sm-flex">
            <div className="col-sm-4">
              <table className="impressum">
                <thead>
                  <tr>
                    <th><strong>Impressum</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Elisabeth Hollekamp <br/> 48619 Heek </td>
                  </tr>
                  <tr>
                    <td>02568 2949</td>
                  </tr>
                  <tr>
                    <td>0174 7346734</td>
                  </tr>
                  <tr>
                    <td>vomdonnerberg [ät] web.de</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-4 offset-sm-4 text-right">
              <table>
                <thead>
                  <tr>
                    <th><strong>Weitere Infos</strong></th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td><button className="link" onClick={() => this.toggleStarsModal()}>Sternchen</button></td>
                </tr>
                <tr>
                  <td><button className="link" onClick={() => this.toggleHavaneseInfoModal()}>Der Havaneser</button></td>
                </tr>
                <tr>
                  <td><button className="link" onClick={() => this.toggleLegalNotesModal()}>rechtliche Hinweise</button></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          { /* footer for mobile */ }
          <div className="row d-sm-none">
            <div className="col-12">
              <table className="impressum">
                <thead>
                  <tr>
                    <th><strong>Impressum</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Elisabeth Hollekamp <br /> 48619 Heek </td>
                  </tr>
                  <tr>
                    <td>02568 2949</td>
                  </tr>
                  <tr>
                    <td>0174 7346734</td>
                  </tr>
                  <tr>
                    <td>vomdonnerberg [ät] web.de</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row d-sm-none">
            <div className="col-12">
              <table>
                <thead>
                  <tr>
                    <th><strong>Weitere Infos</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><button className="link" onClick={() => this.toggleStarsModal()}>Sternchen</button></td>
                  </tr>
                  <tr>
                    <td><button className="link" onClick={() => this.toggleHavaneseInfoModal()}>Der Havaneser</button></td>
                  </tr>
                  <tr>
                    <td><button className="link" onClick={() => this.toggleLegalNotesModal()}>rechtliche Hinweise</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row" id="copyright">
            <div className="col-12">
              <p><span>&copy;</span> Elisabeth Hollekamp</p>
            </div>
          </div>
        </div>
        <LegalNotesModal
          isOpen={this.state.isLegalNotesModalOpen}
          onToggle={() => this.toggleLegalNotesModal()}
        />
        <HavaneseInfoModal
          isOpen={this.state.isHavaneseInfoModalOpen}
          onToggle={() => this.toggleHavaneseInfoModal()}
        />
        <StarsModal
          isOpen={this.state.isStarsModalOpen}
          onToggle={() => this.toggleStarsModal()}
          stars={this.props.stars}
        />
      </footer>
    );
  }
}

export default Footer;
