import React from 'react';
import HavaneseProfile from "./havaneseProfile";

export default class NewsSection extends React.Component {
  render() {
    return (
      <section id="dogs" className="dogs py-5">
        <div className="container-fluid">
          <h2>Unsere Hunde</h2>
          <div className="row justify-content-center">
              {
                this.props.ourDogs.map(havanese => {
                  return (
                    <div key={havanese.id} className="col-xs-12 col-sm-4 d-flex flex-column justify-content-between">
                      <HavaneseProfile havanese={havanese} />
                    </div>
                  )
                })
              }
          </div>
        </div>
      </section>
    )
  }
}
