import React from 'react';
import './welcomeSection.css';

export default class WelcomeSection extends React.Component {
  constructor(props) {
    super(props);
    this.INITIAL_BIG_PICTURES_INDEX = 0;
    this.currentBigPictureCounter = 0;
    this.state = {
      bigPictures: props.bigPictures,
      currentBigPictureIndex: this.INITIAL_BIG_PICTURES_INDEX,
    };
    this.preloadBigPictures();
  }

  componentDidMount() {
    this.bigPictureSwitchInterval = setInterval(() => {
      this.changeBigPicture();
    }, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.bigPictureSwitchInterval);
  }

  changeBigPicture() {
    const currentBigPictureIndex = this.currentBigPictureCounter % this.state.bigPictures.length;
    this.setState({
      currentBigPictureIndex: currentBigPictureIndex,
    });
    this.currentBigPictureCounter++;
  }

  preloadBigPictures() {
    const isSSR = typeof window === "undefined";
    if (!isSSR) {
      this.state.bigPictures.map((bigPicture) => {
        const image = new Image();
        image.src = bigPicture.url;
      });
    }
  }

  render() {
    const bigPictureStyle = {
      backgroundImage: `url('${this.state.bigPictures[this.state.currentBigPictureIndex].url}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    };

    return (
      <section>
        <div
          id='bigPicture'
          className='container-fluid bigPicture'
          style={bigPictureStyle}
        >
          <div id="welcome">
            <div id='innerWelcome'>
              <h1>
                <strong>Herzlich Willkommen</strong>
                <br/>
                <small className="text-muted text">
                  bei den
                  <br/>
                  Havanesern vom Heeker Donnerberg
                </small>
              </h1>
            </div>
          </div>
        </div>
      </section>
    )
  }
};
